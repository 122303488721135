<template>
    <div>
        <div v-for="(item,index) in listData" :key="index">
            <div class="tree_main">
                <div class="icon_main">
                    <div class="icon_box" :class="expandedKey.indexOf(item.id) > -1 ? 'icon_box_down' : ''" v-if="item.children && item.children.length > 0" @click="selectInfo(item,'updown')">
                        <i class="iconfont iconxiala" style="font-size:18px;"></i>
                    </div>
                </div>
                <div class="title_main">
                    <div class="title_box" :class="opStatus ? '' : 'title_box_w'">
                        <div class="title" :class="selectedKey.indexOf(item.id) > -1 ? 'title_activity' : ''" @click="selectInfo(item,'title')">{{ item.title }}</div>
                    </div>
                    <div class="op" v-if="opStatus">
                        <span class="op_icon_main" @click="selectInfo(item,'edit')"><i class="iconfont iconbianji"></i></span>
                        <span class="op_icon_main" @click="selectInfo(item,'delete')" style="color:red;"><i class="iconfont iconshanchu"></i></span>
                    </div>
                </div>
            </div>
            <div class="list_box" v-if="item.children && item.children.length > 0 && expandedKey.indexOf(item.id) > -1">
                <treeList :subInfo="item.children" :expandedKeys="expandedKeys" :selectedKeys="selectedKeys" :opStatus="opStatus" @select='treeSelectItem'></treeList>
            </div>
        </div>
    </div>
</template>
 
<script>

export default {
    name: 'treeList',
    emits:['select'],
    props: {
        subInfo: {
            type: Array,
            default() {
                return {}
            }
        },
        expandedKeys: {
            type: Array,
            default() {
                return []
            }
        },
        selectedKeys: {
            type: Array,
            default() {
                return []
            }
        },
        opStatus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        subInfo: {
            handler(newValue, _) {
                this.listData = newValue
            }
        },
        expandedKeys: {
            handler(newValue, _) {
                this.expandedKey = newValue
            }
        },
        selectedKeys: {
            handler(newValue, _) {
                this.selectedKey = newValue
            }
        }
    },
    data() {
        return {
			listData:[],
            expandedKey:[],
            selectedKey:[]
        }
    },
    created() {
        this.listData = this.subInfo
        this.expandedKey = this.expandedKeys
        this.selectedKey = this.selectedKeys
	},
	methods:{
		selectInfo(info,type){
            this.$emit('select',info,type)
        },
        treeSelectItem(info,type){
            this.$emit('select',info,type)
        }
	}
}
</script>

<style lang="less" scoped>
    .tree_container{
        padding:10px 0;
    }

    .list_box{
        padding-left:15px;
        transition: all 0.3s;
    }

    .tree_main{
        display:flex;
        padding:3px 0;

        .icon_main{
            width:24px;
            display:flex;
            text-align:center;
            align-items: center;

            .icon_box{
                width:100%;
                transition: all 0.3s;
                transform: rotate(-90deg);
                cursor:pointer;
            }

            .icon_box_down{
                transform: rotate(0deg);
            }
        }

        .title_main{
            width:calc(100% - 29px);
            padding-right:5px;
            display:flex;
            align-items: center;

            .title_box{
                width:calc(100% - 60px);

                .title{
                    width:100%;
                    padding:2px 0px 3px 3px;
                    border-radius:2px;
                    cursor:pointer;
                    min-height:27px;
                }

                .title:hover{
                    background:#e6f7ff;
                }

                .title_activity{
                    background:#bae7ff;
                }
                .title_activity:hover{
                    background:#bae7ff;
                }
            }

            .title_box_w{
                width:100%;
            }

            .op{
                width:60px;
                display:none;
                align-items: center;

                .op_icon_main{
                    width:24px;
                    text-align: center;
                    font-size:18px;
                    margin-left:5px;
                    cursor:pointer;
                    display:inline-block;
                    color:#397dee;
                }
            }
        }

        .title_main:hover{
            .op{
                display:block;
            }
        }
    }

</style>
